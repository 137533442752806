<template>
  <div class="buttons sm:flex sm:justify-center lg:justify-start">
    <div v-if="buttons.button1" class="button-1-wrapper">
      <NuxtLink
        :to="buttons.link1"
        :target="buttons.button1Target ? buttons.button1Target : '_self'"
        :class="[
          computedButtonColors[0],
          buttons.buttonSize,
          buttons.button1Color,
          'text-center button button-1 cursor-pointer',
        ]"
        >{{ buttons.button1Text }}</NuxtLink
      >
    </div>
    <div v-if="buttons.button2 && buttons.link2" class="mt-3 button-2-wrapper sm:mt-0 sm:ml-3">
      <NuxtLink
        :to="buttons.link2"
        :target="buttons.button2Target ? buttons.button2Target : '_self'"
        :class="[
          computedButtonColors[1],
          buttons.buttonSize,
          buttons.button1Color,
          'text-center button button-2 cursor-pointer',
        ]"
        >{{ buttons.button2Text }}</NuxtLink
      >
    </div>
    <div v-if="buttons.button3" class="mt-3 button-3-wrapper sm:mt-0 sm:ml-3">
      <NuxtLink
        :to="buttons.link3"
        :target="buttons.button3Target ? buttons.button3Target : '_self'"
        :class="[
          computedButtonColors[2],
          buttons.buttonSize,
          buttons.button1Color,
          'text-center button button-3 cursor-pointer',
        ]"
        >{{ buttons.button3Text }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  buttons: Object,
  buttonColors: Array,
});

const computedButtonColors = computed(() => {
  let classes = ["button-blue", "button-white", "button-orange" , "button-yellow" , "button-gray"];
  //console.log('Initial classes:', classes);
  //console.log('Props button colors:', props.buttonColors);
  if (Array.isArray(props.buttonColors) && props.buttonColors.length) {
    classes = [
      props.buttonColors[0],
      props.buttonColors[1],
      props.buttonColors[2],
      props.buttonColors[3],
      props.buttonColors[4],
    ];
  }
  return classes;
});
</script>
